const localDataName = 'mateBinaryQuestions';
const table = 'binaryQuestions';

const userID = process.env.REACT_APP_USER_ID;
const clientID = process.env.REACT_APP_CLIENT_ID;

const tableColumns = [
  {
    title: 'Benchmark',
    index: 'benchmark_label',
    width: '25%'
  },
  {
    title: 'Question Negative',
    index: 'question_negative',
    width: '25%'
  },
  {
    title: 'Question Positive',
    index: 'question_positive',
    width: '25%'
  }
]

const newItem = {
  benchmark_id: null,
  instruction: '',
  question_negative: '',
  question_positive: '',
  user_id: userID,
  client_id: clientID
}

export {
  table,
  tableColumns,
  localDataName,
  newItem
};
