const actions = {
  GET_ITEM: 'CASES_GET_ITEM',
  UPDATE_ITEM: 'CASES_UPDATE_ITEM',
  UPDATE_ITEM_CASE: 'CASES_UPDATE_ITEM_CASE',
  UPDATE_ITEM_SAGA: 'CASES_UPDATE_ITEM_SAGA',
  SELECT_CURRENT_ITEM: 'CASES_SELECT_CURRENT_ITEM',
  SELECT_CURRENT_ITEM_UPDATE: 'CASES_SELECT_CURRENT_ITEM_UPDATE',
  TOGGLE_VIEW: 'CASES_TOGGLE_VIEW',
  UPDATE_EDIT_ITEM: 'CASES_UPDATE_EDIT_ITEM',
  DELETE_ITEM_SAGA: 'CASES_DELETE_ITEM_SAGA',
  GET_SUMMARY: 'CASES_GET_SUMMARY',
  UPDATE_SUMMARY: 'CASES_UPDATE_SUMMARY',
  LOAD_FORM_SECTION: 'CASES_LOAD_FORM_SECTION',
  UPDATE_FORM_SECTION: 'CASES_UPDATE_FORM_SECTION',
  initData: (params) => ({ type: actions.GET_ITEM, params }),
  deleteItem: deleteItems => {
    return (dispatch, getState) => {
      const items = getState().Cases.items;
      const newItems = [];
      items.forEach(item => {
        const selectedIndex = deleteItems.indexOf(item.id);
        if (selectedIndex === -1) {
          newItems.push(item);
        }
      });
      dispatch({
        type: actions.DELETE_ITEM_SAGA,
        newItems,
        deleteItems
      });
    };
  },
  updateItem: item => ({ type: actions.UPDATE_ITEM_SAGA, item }),
  selectCurrentItem: (id, loadCaseOnly = false) => ({ type: actions.SELECT_CURRENT_ITEM, id, loadCaseOnly }),
  toggleView: view => ({ type: actions.TOGGLE_VIEW, view }),
  editItem: item => ({ type: actions.UPDATE_EDIT_ITEM, item }),
  getSummary: () => ({ type: actions.GET_SUMMARY}),
  updateSummary: (summary) => ({type: actions.UPDATE_SUMMARY, summary}),
  loadFormSection: (id, title) => ({ type: actions.LOAD_FORM_SECTION, id, title }),
};
export default actions;
