import { all, takeEvery, put, call } from 'redux-saga/effects';
import SuperFetch from '../../library/helpers/superFetch';
import notification from '@iso/components/Notification';
import actions from './actions';
import NProgress from 'nprogress';
import 'nprogress/nprogress.css';

export function* getItem({params}) {

  yield put({
    type: actions.UPDATE_ITEM,
    loading: true
  });
  
  yield put({ 
    type: actions.GET_SUMMARY,
    loading: true 
  });

  const { filters = {}, sorter, page, limit } = params || {};
  
  const filterParams = JSON.parse(JSON.stringify(filters))

  Object.keys(filters).forEach(key => {
    if (filters[key] === null) {
      delete filters[key];
    } else if (typeof(filters[key])=== 'object') {
      if(filters[key].length === 1 && !['state', 'status'].includes(key)){
        filters[key] = filters[key].join()
      }
    }
  });
  
  let data = {
    page: page ? page : 1,
    perPage: limit ? limit : 10,
    search: filters,
    sort: {}
  };

  if(sorter?.column) {
    data.sort[sorter.field] = sorter.order === 'ascend' ? 'asc' : 'desc';
  }

  try {
  const response = yield call(SuperFetch.post, 'getCases', { 'page': data.page, ...data.search, ...data.sort })
  if(response?.success) {
    yield put({
      type: actions.UPDATE_ITEM,
      items: response.data,
      count: response.total_count,
      currentPage: { pagination : { current: data.page } },
      loading: false,
      filterParams
    });
  } else if (response?.success === false) {
    notification('error', response.message);
  }
  } catch (error) {
    console.log(error)
  }
}
export function* getSummary() {
  try {
    const response = yield call(SuperFetch.post, 'getCasesSummary', {});
    if (response?.success) {
      yield put({
        type: actions.UPDATE_SUMMARY,
        summary: response.data[0],
      })
    } else {
      notification('error', response.message || 'Failed to fetch dashboard summary data');
    }
  } catch (error) {
    console.log(error);
    notification('error', 'An error occurred while fetching dashboard summary data');
  }
}
export function* updateItemSaga({ item }) {
  let id = false
  if(item.id === 'new') {
    delete item.id
  } else {
    id = item.id === 'new' ? false : item.id
  }
  try {
  const response = yield call(SuperFetch.post, 'saveCase', item)
  if(response?.success) {
    if (!id) { response.data.is_newly_saved = true }
    try {
      yield put({
        type: actions.UPDATE_ITEM,
        item: response.data,
        count: id ? undefined : '+1'
      });
      notification('success', response.message);
      yield put({
        type: actions.SELECT_CURRENT_ITEM,
        id: response.data.id
      });
      yield put({ 
        type: actions.GET_SUMMARY,
        loading: true 
      });
    } catch (error) {
      console.log(error)
    }
  } else if (response?.success === false) {
    if (response?.data?.length) {
      response.data.forEach((e) => {
        if(e.field && e.message) notification('error', `${e.message}: ${e.field}`);
      })
    } else {
      notification('error', response.message);
    }
  }
  } catch (error) {
    console.log(error)
  }
}
export function* loadItem({ id, loadCaseOnly }) {
  NProgress.start();
  if (id !== 'new') {
    NProgress.set(0.2);
    const response = yield call(SuperFetch.post, `getCases`, { id })
    if(loadCaseOnly){
      if(response?.success) {
        yield put({
          type: actions.UPDATE_ITEM_CASE,
          item: response.data[0],
        });
      } else if (response?.success === false) {
        notification('error', response.message);
      }
      NProgress.done();
      return 
    }
    NProgress.set(0.4);
    const formSectionsResponse = yield call(SuperFetch.post, `getFormSections`, { id })
    NProgress.set(0.6);
    const formSections = formSectionsResponse.data
    let currentFormSection
    if(formSections) {
      const title = `${formSections[0]?.title}.${formSections[0]?.sections[0]?.section_title}`
      currentFormSection = yield call(SuperFetch.post, `getFormSectionDetail`, { id, title })
    }
      NProgress.set(0.8);

    if(response?.success) {
      yield put({
        type: actions.UPDATE_ITEM,
        item: response.data[0],
        formSections,
        currentFormSection: currentFormSection?.data[0], 
      });
    } else if (response?.success === false) {
      notification('error', response.message);
    }
    NProgress.done();
  } else {
    yield put({
      type: actions.SELECT_CURRENT_ITEM_UPDATE,
      id
    });
    NProgress.done();
  }
}
export function* loadFormSection({ id, title }) {
  try {
    NProgress.start();
    NProgress.set(0.4);
    const response = yield call(SuperFetch.post, 'getFormSectionDetail', { id, title });
    NProgress.set(0.8);
    if (response?.success) {
      yield put({
        type: actions.UPDATE_FORM_SECTION,
        currentFormSection: response.data[0],
      });
      NProgress.done();
    } else {
      NProgress.done();
      notification('error', response.message || 'Failed to load form section data');
    }
  } catch (error) {
    console.log(error);
    notification('error', 'An error occurred while loading form section data');
  }
}
export function* deleteItems({ newItems, deleteItems, count }) {
  const requestsArray = []
  try {
  deleteItems.forEach(e => {
    requestsArray.push(
      call(SuperFetch.post, `deleteCase`, { id:e })
    )
  })
  const responseArray =yield all(requestsArray)
  let deletedItems = responseArray.filter(e => e.success === true).length
  let unDeletedItems = responseArray.filter(e => e.success === false).length

  if(deletedItems && unDeletedItems === 0) {
    notification('success', `${deletedItems} Case${deletedItems === 1 ? '' : 's'} Deleted`);
    yield put({
      type: actions.UPDATE_ITEM,
      items: newItems
    });
  } else if (unDeletedItems) {
    notification('error', `${unDeletedItems > 1 ? unDeletedItems : ''} Cases${unDeletedItems === 1 ? '' : 's'} linked could not be deleted`);
    if(deletedItems) {
      notification('error', `Please refresh`);
    }
  }
  } catch (error) {
    console.log(error)
  }
}
export default function* rootSaga() {
  yield all([
    yield takeEvery(actions.GET_ITEM, getItem),
    yield takeEvery(actions.UPDATE_ITEM_SAGA, updateItemSaga),
    yield takeEvery(actions.SELECT_CURRENT_ITEM, loadItem),
    yield takeEvery(actions.DELETE_ITEM_SAGA, deleteItems),
    yield takeEvery(actions.GET_SUMMARY, getSummary),
    yield takeEvery(actions.LOAD_FORM_SECTION, loadFormSection),
  ]);
}
