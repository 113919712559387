const localDataName = 'mateCases';
const table = 'cases';

const userID = process.env.REACT_APP_USER_ID;
const clientID = process.env.REACT_APP_CLIENT_ID;

const tableColumns = [
  {
    title: 'ID',
    index: 'id',
    width: '15%'
  },
  {
    title: 'Name',
    index: 'name',
    width: '25%'
  },
  {
    title: 'Case ID',
    index: 'case_id',
    width: '50%'
  },
  {
    title: 'Form',
    index: 'form',
    width: '50%'
  },
  {
    title: 'Output PDF',
    index: 'output_pdf',
    width: '50%'
  },
  {
    title: 'Status',
    index: 'status',
    width: '50%',
    type: 'status',
    filters: [
      { text: 'Pending', value: 0 },
      { text: 'Processing', value: 1 },
      { text: 'Completed', value: 2 },
    ]
  },
]

const newItem = {
  name: null,
  case_id: null,
  form_id: 'needs_assessment',
  formSections: [],
  currentFormSection: {},
  user_id: userID,
  client_id: clientID,
}

export {
  table,
  tableColumns,
  localDataName,
  newItem
};
