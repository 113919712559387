const actions = {
  GET_ITEM: 'CRITERIAS_BINARY_QUESTIONS_GET_ITEM',
  UPDATE_ITEM: 'CRITERIAS_BINARY_QUESTIONS_UPDATE_ITEM',
  UPDATE_ITEM_SAGA: 'CRITERIAS_BINARY_QUESTIONS_UPDATE_ITEM_SAGA',
  SELECT_CURRENT_ITEM: 'CRITERIAS_BINARY_QUESTIONS_SELECT_CURRENT_ITEM',
  SELECT_CURRENT_ITEM_UPDATE: 'CRITERIAS_BINARY_QUESTIONS_SELECT_CURRENT_ITEM_UPDATE',
  TOGGLE_VIEW: 'CRITERIAS_BINARY_QUESTIONS_TOGGLE_VIEW',
  UPDATE_EDIT_ITEM: 'CRITERIAS_BINARY_QUESTIONS_UPDATE_EDIT_ITEM',
  DELETE_ITEM_SAGA: 'CRITERIAS_BINARY_QUESTIONS_DELETE_ITEM_SAGA',
  initData: (params) => ({ type: actions.GET_ITEM, params }),
  deleteItem: deleteItems => {
    return (dispatch, getState) => {
      const items = getState().BinaryQuestions.items;
      const newItems = [];
      items.forEach(item => {
        const selectedIndex = deleteItems.indexOf(item.id);
        if (selectedIndex === -1) {
          newItems.push(item);
        }
      });
      dispatch({
        type: actions.DELETE_ITEM_SAGA,
        newItems,
        deleteItems
      });
    };
  },
  updateItem: item => ({ type: actions.UPDATE_ITEM_SAGA, item }),
  selectCurrentItem: id => ({ type: actions.SELECT_CURRENT_ITEM, id }),
  toggleView: view => ({ type: actions.TOGGLE_VIEW, view }),
  editItem: item => ({ type: actions.UPDATE_EDIT_ITEM, item }),
};
export default actions;
