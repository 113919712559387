const localDataName = 'mateCriteria';
const table = 'criteria';

const userID = process.env.REACT_APP_USER_ID;
const clientID = process.env.REACT_APP_CLIENT_ID;

const tableColumns = [
  {
    title: 'ID',
    index: 'id',
    width: '15%'
  },
  {
    title: 'Name',
    index: 'name',
    width: '25%'
  },
  {
    title: 'Assessment Id',
    index: 'assessment_id',
    width: '25%'
  },
  {
    title: 'Created At',
    index: 'created_at',
    width: '25%'
  }
]

const newItem = {
  name: '',
  description:'',
  assessment_id: null,
  level: null,
  parent_id: null,
  user_id: userID,
  client_id: clientID
}

export {
  table,
  tableColumns,
  localDataName,
  newItem
};
