const localDataName = 'mateBenchmarks';
const table = 'benchmarks';

const userID = process.env.REACT_APP_USER_ID;
const clientID = process.env.REACT_APP_CLIENT_ID;

const tableColumns = [
  {
    title: 'Label',
    index: 'benchmark_label',
    width: '25%',
    sortable: true
  },
  {
    title: 'Code',
    index: 'benchmark_code',
    width: '25%',
    sortable: true
  },
  {
    title: 'Assessment',
    index: 'assessment_name',
    width: '25%',
    sortable: true
  },
  {
    title: 'Criteria',
    index: 'assessment_criteria_name',
    width: '25%',
    sortable: true
  },
  {
    title: 'Score',
    index: 'score',
    width: '25%'
  },
  {
    title: 'Updated At',
    index: 'updated_at',
    type: 'date',
    width: '25%'
  }
]

const newItem = {
  benchmark_label: '',
  benchmark_code: '',
  color_code: null,
  assessment_criteria_id: null,
  score: null,
  user_id: userID,
  client_id: clientID
}

export {
  table,
  tableColumns,
  localDataName,
  newItem
};
