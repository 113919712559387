import { all, takeEvery, put, fork, call } from 'redux-saga/effects';
import SuperFetch from '../../library/helpers/superFetch';
import { getToken, clearToken, getUserInfo, getClientInfo, clearLocalData } from '@iso/lib/helpers/utility';
import actions from './actions';

const fakeApiCall = true; // auth0 or express JWT

export function* loginRequest() {
  yield takeEvery('LOGIN_REQUEST', function*({ payload, refresh }) {
    const prevUserInfo = localStorage.getItem('user_info') ? JSON.parse(localStorage.getItem('user_info')) : {};
    const prevClientInfo = localStorage.getItem('client_info') ? JSON.parse(localStorage.getItem('client_info')) : {};
    let { token, profile, userInfo, clientInfo } = payload;
    if(prevUserInfo.username === userInfo.username) {
      prevUserInfo.refresh_token = userInfo.refresh_token
      userInfo = prevUserInfo
      clientInfo = prevClientInfo
    }
    if(refresh === true) {
      yield localStorage.setItem('id_token', token);
      yield localStorage.setItem('user_info', JSON.stringify(userInfo));
      if(userInfo?.client?.length === 1) {
        const prevClientInfo = localStorage.getItem('client_info') || false;
        if(!prevClientInfo || !["admin","owner"].includes(userInfo.role_code)) {
          yield localStorage.setItem('client_info', JSON.stringify(userInfo?.client[0]));
        }
      }
      return;
    }

    if (token) {
      yield put({
        type: actions.LOGIN_SUCCESS,
        token,
        profile,
        userInfo,
        clientInfo
      });
    } else {
      if (fakeApiCall) {
        yield put({
          type: actions.LOGIN_SUCCESS,
          token: 'secret token',
          profile: 'Profile',
        });
      } else {
        yield put({ type: actions.LOGIN_ERROR });
      }
    }
  });
}

export function* loginSuccess() {
  yield takeEvery(actions.LOGIN_SUCCESS, function*(payload) {
    yield localStorage.setItem('id_token', payload.token);
    yield localStorage.setItem('user_info', JSON.stringify(payload.userInfo));
    if(payload.userInfo?.client?.length === 1) {
      const prevClientInfo = localStorage.getItem('client_info') || false;
      if(!prevClientInfo || !["admin","owner"].includes(payload.userInfo.role_code)) {
        yield localStorage.setItem('client_info', JSON.stringify(payload.userInfo?.client[0]));
      }
    }
  });
}

export function* loginError() {
  yield takeEvery(actions.LOGIN_ERROR, function*() {});
}

export function* logout() {
  yield takeEvery(actions.LOGOUT, function*() {
    yield clearToken();
    window.location.replace('/');
  });
}

export function* setClient() {
  yield takeEvery(actions.SET_CLIENT, function*({payload}) {
    const response = yield call(SuperFetch.post, 'getClients', { id: payload })
    if(response?.success) {
      const clientInfo = response.data[0]
      yield put({
        type: actions.SET_CLIENT_SUCCESS,
        payload: clientInfo
      });
      yield clearLocalData(JSON.stringify(clientInfo));
      if (clientInfo.ca_enable && !clientInfo.ac_enable && !clientInfo.cc_enable) {
        window.location.href="/dashboard/cases";
      } else {
        window.location.href="/dashboard";
      } 
    }
  });
}

export function* checkAuthorization() {
  yield takeEvery(actions.CHECK_AUTHORIZATION, function*() {
    const token = getToken().get('idToken');
    const userInfo = getUserInfo().get('userInfo');
    const clientInfo = getClientInfo().get('clientInfo');
    if (token) {
      yield put({
        type: actions.LOGIN_SUCCESS,
        token,
        userInfo,
        clientInfo
      });
    }
  });
}
export default function* rootSaga() {
  yield all([
    fork(checkAuthorization),
    fork(loginRequest),
    fork(loginSuccess),
    fork(loginError),
    fork(logout),
    fork(setClient),
  ]);
}
