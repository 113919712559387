import { combineReducers } from 'redux';
import App from '@iso/redux/app/reducer';
import Auth from '@iso/redux/auth/reducer';
import ThemeSwitcher from '@iso/redux/themeSwitcher/reducer';
import Ecommerce from '@iso/redux/ecommerce/reducer';
import LanguageSwitcher from '@iso/redux/languageSwitcher/reducer';
import Courses from '@iso/redux/courses/reducer';
import Users from '@iso/redux/users/reducer';
import Learners from '@iso/redux/learners/reducer';
import Clients from '@iso/redux/clients/reducer';
import Contents from '@iso/redux/contents/reducer';
import Tenancies from '@iso/redux/tenancies/reducer';
import Submissions from '@iso/redux/submissions/reducer';
import Assessments from '@iso/redux/assessments/reducer';
import Criteria from '@iso/redux/criterias/reducer';
import Benchmarks from '@iso/redux/benchmark/reducer';
import BinaryQuestions from '@iso/redux/binaryQuestions/reducer';
import AssessmentsGrades from '@iso/redux/assessmentsGrades/reducer';
import Settings from '@iso/redux/settings/reducer';
import Cases from '@iso/redux/cases/reducer';

export default combineReducers({
  Auth,
  App,
  ThemeSwitcher,
  Ecommerce,
  LanguageSwitcher,
  Courses,
  Cases,
  Users,
  Learners,
  Clients,
  Contents,
  Tenancies,
  Submissions,
  Assessments,
  Criteria,
  Benchmarks,
  BinaryQuestions,
  AssessmentsGrades,
  Settings,
});
