import { all } from 'redux-saga/effects';
import authSaga from '@iso/redux/auth/saga';
import ecommerceSaga from '@iso/redux/ecommerce/saga';
import coursesSaga from '@iso/redux/courses/saga';
import usersSaga from '@iso/redux/users/saga';
import learnersSaga from '@iso/redux/learners/saga';
import clientsSaga from '@iso/redux/clients/saga';
import contentsSaga from '@iso/redux/contents/saga';
import tenanciesSaga from '@iso/redux/tenancies/saga';
import submissionsSaga from '@iso/redux/submissions/saga';
import assessmentsSaga from '@iso/redux/assessments/saga';
import criteriaSaga from '@iso/redux/criterias/saga';
import benchmarksSaga from '@iso/redux/benchmark/saga';
import binaryQuestionsSaga from '@iso/redux/binaryQuestions/saga';
import assessmentsGradesSaga from '@iso/redux/assessmentsGrades/saga';
import settingsSaga from '@iso/redux/settings/saga';
import casesSaga from '@iso/redux/cases/saga';

export default function* rootSaga(getState) {
  yield all([
    authSaga(),
    ecommerceSaga(),
    coursesSaga(),
    casesSaga(),
    usersSaga(),
    learnersSaga(),
    clientsSaga(),
    contentsSaga(),
    tenanciesSaga(),
    submissionsSaga(),
    assessmentsSaga(),
    criteriaSaga(),
    benchmarksSaga(),
    binaryQuestionsSaga(),
    assessmentsGradesSaga(),
    settingsSaga(),
  ]);
}
